import * as React from "react"
import Layout from "../components/layout"
import { Col } from "react-bootstrap"
import Seo from "../components/seo"
import "./support.scss"


const ContactUsPage = () => {
  return (
    <Layout>
      <Seo title="Contact us page" />
      <div className="contentalign">
          <h4>Contact us at :</h4>        
          <span>Email - visa2explore@gmail.com</span><br/>          
      </div>
    </Layout>)
}

export default ContactUsPage

